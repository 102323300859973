<template>
  <div class="wrapper">
    <div class="main">
      <!-- 1 理事会成员 -->
      <div id="council-member-wrapper" class="part council-member">
        <a id="council-members"></a>
        <div class="title">
          <div>发起人</div>
          <div>/ Originator</div>
        </div>
        <div style="color: #7a7a7a; margin-bottom: 10px">
          (以下排名不分先后)
        </div>
        <div v-for="(item, index) in data" :key="index" class="originator">
          <div class="name">{{ item.name }}</div>
          <div class="corporation">{{ item.company }}</div>
          <div class="position">{{ item.position }}</div>
        </div>
      </div>
      <!-- 3 组织框架 -->
      <div id="organizing-frame-wrapper" class="part">
        <a id="organizing-frame"></a>

        <div class="wrapper">
          <div class="title">
            <div>组织框架</div>
            <div>/ Member of the board of supervisors</div>
          </div>
        </div>

        <!-- content -->
        <div class="content">
          <div>
            <img :src="img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TimeLineNav from "@/components/About/TimeLineNav";

import { getConfig } from "@/api/category";
import { formatConfig } from "@/utils/formatConfig";

export default {
  name: "Council",
  components: {
    // TimeLineNav
  },
  data() {
    return {
      Originator: [
        ["曹  旷", "大力神铝业股份有限公司", "总经理"],
        ["李  平", "辽宁联合物流有限公司", "总经理"],
        ["卢小波", "开勒环境科技（上海）股份有限公司", "董事长"],
        ["罗  贵", "浙江网仓科技有限公司", "COO"],
        ["马乃东", "上海莲森实业(集团)有限公司", "副总裁"],
        ["欧步金", "富欧联合控股集团有限公司", "董事长"],
        ["孙  超", "淮南润成科技股份有限公司", "法人代表、总经理"],
        ["唐  坚", "艾思博投资有限公司", "总经理"],
        ["晏许勇", "上海创创营销策划有限公司", "董事长"],
        ["张  骥", "千年资本中国发展基金管理有限公司", "主管合伙人"],
        ["赵  钱", "上海照寰文化传播有限公司", "总经理"],
        ["周俊良", "杭州西奥电梯有限公司", "总裁"],
        ["陈  辰", "上海文广新闻传媒集团综艺部", "主持人兼制片人"],
        ["陈凯华", "上海扬派贸易有限公司", "总经理"],
        ["贾  明", "北京本来工坊科技有限公司", "副总裁"],
        ["李  可", "上海统略资产管理有限公司", "创始合伙人"],
        ["龙  陈", "上海邦信阳中建中汇律师事务所", "合伙人/律师"],
        ["罗  妍", "香港路劲地产-上海公司", "常务副总/董事"],
        ["潘至平", "上海银音信息科技股份有限公司", "董事长"],
        ["邵  勇", "四川海润机电工程有限公司", "总经理"],
        ["吴启行", "阳光建设集团", "副董事长"],
        ["张国平", "南京中脉科技发展有限公司", "全球营销总裁"],
        ["赵泽俊", "广西建工五建公司", "董事、副总经理"],
        ["朱  锋", "江苏凤凰船舶装备制造有限公司", "董事"],
        ["岑  哲", "宁波瑞德电器有限公司", "董事长"],
        ["常海松", "上海伟亿投资管理有限公司", "总裁"],
        ["陈国雄", "艺星国际医疗美容集团", "总裁"],
        ["陈奕仁", "泛亚环境国际控股有限公司", "董事总经理"],
        ["郭华伟", "中美医疗集团", "总裁"],
        ["金宇晴", "一兆韦德健身管理有限公司", "董事长"],
        ["李晓明", "山东卓灿贸易有限公司", "总经理"],
        ["郦霏霏", "江苏宝尊投资集团有限公司", "副总裁"],
        ["瞿天锋", "上海途启科技有限公司", "总经理"],
        ["吴历华", "超竞集团", "联合创始人CEO/EDG电竞俱乐部总裁"],
        ["赵  萍", "上海龙瑞文化广告传媒有限公司", "总经理"],
        ["郑建兵", "深圳城市嘉实投资管理有限公司", "总经理"],
        ["蔡雨峰", "希捷公关集团", "董事长"],
        ["郭天劲", "上海添劲实业有限公司", "董事长"],
        ["郝  杰", "杭州电魂网络科技股份有限公司", "联合创始人、副总经理"],
        ["黄开添", "上海恒奕投资管理有限公司", "董事长"],
        ["江欣庭", "方方中国乌干达有限公司", "董事"],
        ["姜  威", "上海工程勘察设计有限公司", "副总经理"],
        ["马妍星", "上海万致网络信息科技有限公司", "董事长/法人"],
        ["师欣欣", "德马吉国际展览有限公司", "总经理"],
        ["王  栋", "圳明见乐为科技有限公司", "联合创始人"],
        ["徐  蕾", "上海相宜本草化妆品股份有限公司", "市场总监"],
        ["郑  波", "红纺文化集团", "董事长"],
        ["周  峰", "南京宸旭汽车部件有限公司", "总经理"],
        ["黄有来", "上海荷人商贸有限公司", "董事长"],
        ["李文超", "山东境界企业管理咨询有限公司", "董事长"],
        ["刘红平", "湖南洋光在线教育科技有限责任公司", "董事长"],
        ["刘建波", "上海东方世贸集团", "董事长"],
        ["马  铮", "浙江自立股份有限公司", "副总裁兼董秘"],
        ["宋  捷", "嘉弘企业（中国）发展有限公司", "董事长/法人"],
        ["吴显义", "浙江卓诗尼控股有限公司", "董事长兼总裁"],
        ["许云龙", "上海紫藤花涂料有限公司", "董事长"],
        ["杨  凌", "上海万途投资管理有限公司", "合伙人"],
        ["尹  争", "合肥华邦集团有限公司", "副总裁"],
        ["余  慧", "元物宇数字科技有限公司", "创始人"],
        ["郭磊峰", "宇业集团有限公司", "财务总监/副总裁"],
        ["胡  钰", "上海启之网络科技有限公司", "董事长"],
        [
          "李  扬",
          "杜比实验室国际技术服务（上海）有限公司",
          "总监，亚太区授权合规审计"
        ],
        ["连瑶华", "天元资本", "风险控制主管"],
        ["陆家华", "香飘飘食品股份有限公司", "董事/副总经理"],
        ["齐德稳", "上海普聚塑料科技有限公司", "总经理"],
        ["孙春龙", "深圳市止戈文化传媒有限公司", "创始人"],
        ["王珮瑜", "上海京剧院", "演员"],
        ["王懿祺", "北京金源互动科技有限公司", "联席CEO"],
        ["吴昊天", "蓝城房产建设管理集团有限公司", "副总裁"],
        ["徐林海", "南京奥派信息产业股份公司", "董事长"],
        ["薛来民", "上海蒙翰贸易发展有限公司", "总经理"],
        ["周  颖", "长江商学院EMBA26期5班", "班主任"]
      ],
      data: [],
      img: ""
    };
  },
  mounted() {
    // 跳转到该页面时从顶部开始
    // // chrome
    // document.body.scrollTop = 0;
    // // firefox
    // document.documentElement.scrollTop = 0;
    // // safari
    // window.pageYOffset = 0;
    this.getConfig();
    this.getImgConfig();
  },
  methods: {
    async getConfig() {
      const res = await getConfig("CouncilConfig");
      if (res !== null) {
        try {
          this.data = JSON.parse(res.value);
        } catch (e) {
          this.data = [];
        }
      }
    },
    async getImgConfig() {
      const res = await getConfig("OtherConfig");
      try {
        this.img = formatConfig(
          JSON.parse(res.value),
          "organizationalFramework"
        );
      } catch (e) {
        this.img = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// .content {
//   width: 200px;
//   height: 200px;
//   background-color: skyblue;
//   margin: 50px auto;
// }
// 主要内容
.main {
  // height: 100vh;
  // width: 1100px;
  margin: 0 auto;
  > div {
    margin-top: 0;
  }
}
.part {
  width: 1100px;
  // height: 740px;
  position: relative;
  margin: 0 auto;
  .title {
    font-family: "AlibabaPuHuiTi-Regular" !important;
    text-align: start;
    margin: 10px 10px 20px 10px;
    div {
      display: inline;
    }
    div:first-child {
      font-size: 24px;
      color: #000;
    }
    div:last-child {
      margin-left: 20px;
      color: #ccc;
    }
  }
}
// public
.content {
  .members-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 205px;
      height: 265px;
      margin: 20px 6px;
      overflow: hidden;
      background-color: #000;
      position: relative;
      .member-info {
        position: absolute;
        width: 100%;
        padding: 0 26px;
        height: 76px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        div:first-child {
          font-size: 22px;
        }
        div:nth-child(2) {
          font-size: 14px;
        }
      }
      img {
        width: 205px;
      }
    }
  }
}
// part1 理事会成员
#council-member-wrapper {
  // height: 1430px;
  #council-members-list {
    li {
      position: relative;
      .member-info {
        background-color: rgba($color: #004898, $alpha: 0.5);
        position: absolute;
      }
    }
  }
}
#board-of-supervisors-wrapper {
  height: 530px;
  #supervisors-members-list {
    li {
      .member-info {
        background-color: rgba($color: #004898, $alpha: 0.5);
      }
    }
  }
}
#organizing-frame-wrapper {
  height: 800px;
  margin-top: 80px;
  .content {
    margin-top: 100px;
    display: flex;
    align-items: center;
    > div {
      width: 1130px;
      img {
        width: 100%;
      }
    }
  }
}
.originator {
  display: flex;
  color: #1d1d1f;
  line-height: 34px;
  .name {
    width: 50px;
    text-align: justify;
    text-justify: distribute-all-lines;
    text-align-last: justify;
  }
  .corporation {
    margin-left: 300px;
    width: 450px;
  }
}
</style>
